$ellipsis-font-size: 13px;
$ellipsis-line-height: 1.2;
$ellipsis-lines-to-show: 3;

.ellipsisx3 {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box!important;
    max-width: 240px;
    height: $ellipsis-font-size*$ellipsis-line-height*$ellipsis-lines-to-show; /* Fallback for non-webkit */
    margin: 0;
    font-size: $ellipsis-font-size;
    line-height: $ellipsis-line-height;
    -webkit-line-clamp: $ellipsis-lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    //overflow: hidden;
    padding: 0;
    margin: 0
}

#root {
    overflow: auto;
    //height: 100vh;
}

.preload {
    color: #606060;
    margin: 0;
    padding: 0;
    background-color: #F0F0F0;
    pointer-events: none;
    .col_content {
        @media (min-width: 960px) {
            max-width: 70%;
            flex-basis: 70%;
            padding-top: 40px!important;
        }
    }
    .col_sidebar {
        
        @media (min-width: 960px) {
            max-width: 30%;
            flex-basis: 30%;
            padding-top: 40px!important;
        }
    }
   
    .MuiGrid-container {
        margin: auto;
        max-width: 1280px;
    }
    .main {
        height: calc(100vh - 188px);
        overflow: hidden;
    }
    .preload-header {
        background-color: white; 
        padding: 0;  
        width: 100%;
        height: 56px;  
        margin-bottom: 25px;
        @media (min-width: 960px){
            height: 78px; 
            margin-bottom: 45px;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);   
        }
        .MuiToolbar-gutters {
            padding-left: 24px;
            padding-right: 24px;
            @media (max-width: 960px) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
        .logo {
            max-width: 100%;
        }
        
        @media (max-width: 959.95px) {
            .logo {
                max-height: 40px;
                margin-top: 6px;
            }
        }
    }
    .preload-footer {
        height: 110px;    
        padding: 25px 0;
        background-color: #e8e8e8;
    }
    .responsive{
        svg {
            width: 100%;
            height: auto;
        }
    }
}

.payment_resume_selected {
    .MuiTypography-root.MuiTypography-h2 {
        @media (max-width: 960px) {
            margin-top: 13px;
            margin-bottom: 20px!important;
        }
    }
    .bank_image_selected {
        @media (max-width: 960px) {
            margin-bottom: 10px;
        }
    }
}

p {
    margin: 0;
}

img {
    max-width: 100%;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.strong {
    font-weight: 600!important;
}

.uppercase {
    text-transform: uppercase!important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.align-right {
    margin-right: 0!important;
    margin-left: auto!important;
}

.mt-0 {
    margin-top: 0!important;
}
.mb-0 {
    margin-bottom: 0!important;
}
.pt-0 {
    padding-top: 0!important;
}
.pb-0 {
    padding-bottom: 0!important;
}

.mb-05 {
    margin-bottom: .5em!important;
}

.mb-1 {
    margin-bottom: 1em!important;
}
.mb-2 {
    margin-bottom: 2em!important;
}
.mt-05 {
    margin-top: .5em!important;
}
.mt-1 {
    margin-top: 1em!important;
}
.mt-2 {
    margin-top: 2em!important;
}

.MuiTypography-root {
    &.MuiTypography-h2 {
        margin-bottom: 25px;
    }
}

.paper {
    padding: 16px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}

.avatar_purchase_detail.MuiAvatar-root {
    overflow: visible;
}



.purchase_card_modal {
    .MuiCardHeader-root,
    .MuiCardContent-root,
    .MuiCardContent-root:last-child {
        padding: 18px 16px;
    }
    .MuiCardHeader-root {
        padding-bottom: 0;
    }  
}

.detail_resume {
    padding: 12px;
    background-color: #fff;
    .item_resume {
        margin-bottom: 12px;
        
    }
    .item_resume_price {
        padding-top: 12px;
        border-top: 1px solid #dddddd;
        .amount {
            font-size: 22px;
            font-weight: 600;
        }
    }
}

.card_detail {
    margin-bottom: 16px;
    .card_detail_header {
        border-bottom: 1px solid #dddddd;
    }
    .detail_item_title {
        vertical-align: top;
    }
    .title_card_header {
        margin-bottom: 4px;
    }
}

.oneCol {
    .detail_item {
        margin-bottom: 15px;
    } 
}

.button_add_product .material-icons,
.button_remove_product .material-icons {
    margin-right: 5px;
}

.secure_site {
    display: flex;
    align-items: center;
    
    &.paper {
        padding: 12px;
    }
    .icon_secure_site {
        flex: 0 0 auto;
        margin-right: 16px;
        margin-top: 0;
        margin-bottom: auto;
        .avatar {
            width: 40px;
            height: 40px;
            display: flex;
            overflow: hidden;
            user-select: none;
            border-radius: 50%;
            justify-content: center;
            border: 1px solid rgba(0, 0, 0, 0.2);
            .material-icons {
                margin: auto;
            }
        }
    }
    .text_secure_site {
        flex: 1 1 auto;
        text-align: center;
        p {
            font-size: 13px;
            padding-right: 45px;
            margin-bottom: 15px;
        }
        .logos-sitio-seguro {
            max-width: 200px;
            padding-right: 45px;
        }
    }
}

.container_item_resume {
    position: relative;
    p >span {
        float: left;
        max-width: 87%;
    }
    .info_charge_button.MuiIconButton-root{
        float: left;
    }
}

.info_charge_button.MuiIconButton-root {
    padding: 0;
    position: relative;
    margin-left: 5px;
    top: -2px;
    .material-icons {
        font-size: 22px;
    }
}

.modal .primary_color.MuiPaper-root {
    .info_charge_button.MuiIconButton-root {
        color: white;
        margin-left: 8px;
    }
}

.item_resume {
    margin-bottom: 4px;
    /* span {
        font-size: 14px;
    } */
}

.sticky-inner {
    .MuiPaper-root {
        margin: 0;
        border-radius: 0;
    }
    .timer {
        box-shadow: none;
    }
}

.sticky {
    z-index: 100;
    width: 100%;
    .sticky-inner {
        width: 100%;
    }
}

.MuiGrid-item {
    width: 100%;
}

.text-offer {
    font-style: italic;
    font-weight: 600;
}
.item_resume.text-offer {
    font-weight: normal;
    font-style: normal;
}
.payment_resume_selected {
    margin-bottom: 35px;
    .total_amount {
        font-size: 22px;
        font-weight: 600;
        line-height: 16px;
    }
    .card_tea {
        font-size: 12px;
    }
    
    .card_cft {
        font-size: 26px;
        margin-top: -8px;
    }
    .bank_image_selected {
        border-radius: 3px;
        border: 1px solid #e4e4e4;
        max-width: 90px;
        padding: 2px 5px;
    }

    .credit_card_image_selected {
        max-height: 45px;
        margin-top: 4px;
    }
}



.card_product_header {
    position: relative;
}

.promo_label {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    .content_label {
        position: relative;
        .triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 85px 85px 0;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }
        .text {
            padding: 6px 10px 0;
            z-index: 1;
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 80px;
            height: 80px;
            text-align: center;
            font-size: 12px;
            white-space: break-spaces;
        }
    }
}

.crossell_resume_single_card {
    .promo_label {
        top: 10px;
        border-radius: 25px 0 0 25px;
        margin-bottom: 15px;
        
        .content_label {
            .text {
                display: block;
                transform: none;
                width: inherit;
                height: inherit;
                font-size: 16px;
                padding: 4px 26px;
                span {
                    display: inline-block;
                    vertical-align: top;
                }
                .MuiIcon-root {
                    font-size: 16px;
                    margin-top: 4px;
                }
            }
        }
    }
    .img_provider {
        margin-bottom: 8px;
    }
    .MuiButton-root:hover {
        background-color: transparent;
    }
}

.crossell_products {
    .card_product {
        margin-bottom: 0;
    }
    .slick-list {
        margin: 0 -8px;
    }
    .carousel_item {
        padding: 8px;
        &:focus {
            outline: none;
        }
    }
}

.datepicker {
    width: 100%;
}

.card_detail_header {
    .MuiCardHeader-avatar {
        top: 3px;
        position: relative;
    }
}

.MuiTypography-h5 {
    margin-bottom: 26px;
}
.align-center {
    justify-content: center!important;
}
.align-right {
    justify-content: flex-end!important;
}
.align-left {
    justify-content: flex-start!important;
}

body .timer .time {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.MuiCardHeader-content {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    > span {
        margin-bottom: 0;
    }
}

.info_outline {
    font-size: 1.4em !important;
}

.has-promo {
    text-align: center;
}

.col-right.has-promo {
    text-align: right;
}

.MuiButton-iconSizeMedium > *:first-child {
    font-size: 22px!important;
}

.slick-slider {
    padding: 0 10px;
}

.card_divider {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */

.flex_content {
    display: flex;
    height: 100%; /* 1, 3 */
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    
}

.flex_content_header,
.flex_content_footer {
flex: none; /* 2 */
}

.flex_content_body {
display: flex;
flex: 1 0 auto; /* 2 */
flex-direction: column;
padding: var(--space);
padding: 0 25px;
}

.stepPayment {
@media (min-width: 960px) {
    min-height: 40vh;
}
}

@media (--break-lg) {
.flex_content_body {
    flex-direction: row;
}
}

.MuiPickersBasePicker-container {
.MuiPickersDay-day {
    color: #606060!important;
}
.MuiPickersDay-daySelected {
    color: white!important
}
.MuiPickersDay-dayDisabled {
    color: rgba(0, 0, 0, 0.38)!important;
}
.MuiPickersToolbarButton-toolbarBtn {
    padding: 0!important;
    min-width: 16px;
    text-transform: none!important;
}
}

.bg-dacffd {
    background-color: #dacffd;
}

.preload-open {
    overflow: hidden;
    pointer-events: none;
}

.preload-common {
    .container-preload-image  {
        .inner-preload-image {
            margin: auto;
        }
    }
}

.container-preload-image {
    pointer-events: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 18vh 25px;
    background: rgba(255, 255, 255, 0.6);

    .inner-preload-image {
        max-width: 900px;
        margin: auto;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        height: fit-content;
        .title {
            font-weight: normal;
            font-size: 26px;
        }
        
    }
}

  .preload-booking {
      .title {
          font-weight: normal;
          font-size: 26px;
      }
      .description {
          font-size: 20px;
      }
  }

  .message_button_action a {
      text-decoration: none;
  }

  #payment[getwayPayment][cvc].MuiOutlinedInput-input {
    &::placeholder {
        text-decoration: none!important;
    }
  }

  .info-confirmation {
    
    font-size: 13px!important;
    font-style: italic;
    padding: 14px 10px 0;
    position: relative;
    padding-right: 40px;
    .text {
        color: #ababab;
        @media (max-width: 960px) {
            display: none;
        }
    }
    .material-icons {
        font-size: 30px !important;
        position: absolute;
        right: 5px;
        top: 8px;
        
    }
  }
  .card_credit_card {
      position: relative;
      border: 1px solid white;
      &:hover {
        border-color: #5599D6;
    }
    .MuiCardHeader-content {
        padding-right: 25px;
    }
    .MuiButton-root {
        width: 100%;
        padding: 0;
        .MuiCardHeader-root {
            width: 100%;
            text-align: left;
        }
        .card_credit_card_header {
            .MuiCardHeader-action {
                top: 0;
                bottom: 0;
                right: 16px;
                margin: auto;
                position: absolute;
                width: 24px;
                height: 24px;
            }
        }

        .card_credit_card_header_v2 {
            .MuiCardHeader-avatar {
                .MuiIcon-root {
                    top: 4px;
                    position: relative;
                }
            }
        }
        
        p {
            color: #606060
        }
    }
      
  }

  .terminosycondiciones {
    margin-bottom: 25px;
  }

.error-terminos-condiciones.MuiFormHelperText-root.Mui-error {
    color: #f44336;
    font-size: 12px;
    display: block;
    width: 100%;
    position: relative;
    top: -8px;
    left: 41px;
}

.payment_resume_selected {
    .MuiGrid-spacing-xs-4 > .MuiGrid-item {
        @media (max-width: 960px) {
                padding: 0 12px 12px 12px;
        }
    }
}

.slick-track {
    margin-left: 0;
}

.steps_container .advice_content_information_b {
    text-align: justify;
}


.datepicker  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
    .MuiInputBase-input {
        padding-left: 0;
    }
}

/* Preloader V2 */

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 14px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* End preloader V2 */